import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../../components/en/common/Layout'
import { MetaData } from '../../components/common/meta'
import ContactForm from '../../components/en/sections/contact-form'
import HeroBig from '../../components/sections/hero-big'
import OurExpertiseBoxes from '../../components/en/sections/our-expertise-boxes'
import SectionHeader from '../../components/common/section-header'
import PortfolioMasonry from '../../components/en/sections/portfolio-masonry'
import OurPartners from '../../components/sections/our-partners'
import Testimonials from '../../components/en/sections/testimonials-static'
import OurServices from '../../components/en/sections/our-services'

import schema from '../../utils/schema/en/general'

/**
* Main index page (home page)
**/
const Index = ({ data, location }) => {
  const posts = data.indexPosts.edges

  return <>
    <MetaData
      title="Home | Think Plus - Advertising"
      description="We specialize in: Software Development, Web Design, Video &amp; Photography, Online Marketing, Shopify eCommerce, WordPress Development, E-Shop Development, SEM, SEO, PPC, Web Design, Performance Marketing."
      location={location}
      linkAlterante={false}
    />
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema.localBusiness)}</script>
      <script type="application/ld+json">{JSON.stringify(schema.organization)}</script>
      <link rel="alternate" href="https://think-plus.gr/" hreflang="el-gr" />
      <link rel="alternate" href="https://think-plus.gr/en/" hreflang="en" />
    </Helmet>
    <Layout>
      <HeroBig
        headerTitle={"Advertising + Developing + Production"}
        headerSubtitle={"Think sales, think awareness!"}
        ctaImage={data.heroImage.childImageSharp.gatsbyImageData}
        ctaTitle={"Websites, e-shops, ads & more!"}
        ctaText={"Think about an agency that can help you advertise your products in the best possible way. That can build your successful campaign, your beautiful site, your efficient e-shop, handle all your social media and create your tailor-made apps. Think about professionals with many years of experience in their field, with one goal: to cover your company's every need."}
      />
      <article className="background__theme home-services">
        <OurServices decoration={true} subtitle={true} />
      </article>
      <OurExpertiseBoxes />
      <article className="container">
        <SectionHeader title="PORTFOLIO" text="If you are still thinking about it,<br>take a look at our Portfolio." />
        <PortfolioMasonry postList={posts} />
        <div className="portfolio-see-all">
          <a href="/en/our-work/" title="See all works" className="btn--more">See all works &rarr;</a>
        </div>
      </article>
      <OurPartners headerTitle="These are some brands that have stopped thinking about it!" link={false} />
      <Testimonials classname="background__theme" />
      <ContactForm  />
    </Layout>
  </>;
}

export default Index

export const pageQuery = graphql`{
  indexPosts: allGhostPost(
    sort: {order: DESC, fields: [published_at]}
    filter: {tags: {elemMatch: {name: {eq: "#portfolio"}}}}
    limit: 12
  ) {
    edges {
      node {
        ...GhostPostFields
      }
    }
  }
  heroImage: file(relativePath: {eq: "hero/hero-index.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`