import React from 'react'

import FeatureImage from '../../common/FeatureImage'

class PortfolioCard extends React.Component {
  
  render() {
    return (
      <div className="portfolio-card">
        <FeatureImage item={this.props.post} />
      </div>
    )
  }
}

export default PortfolioCard