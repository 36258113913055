import React from 'react'

import '../../../styles/components/our-expertise-boxes.scss'

class OurExpertiseBoxes extends React.Component {
    render() {
        return (
            <section className="our-expertise-boxes">
                <div className="container medium">
                    <div className="row text-align-center">
                        <div className="col">
                            <h2 className="dec-header--underline">Don’t just think about it… Think+!</h2>
                            <div className="py-5">
                                <p>While you are thinking about it… take a look at why you shouldn’t be! These are our “know how’s”.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="grid-container--three expertise-boxes__container">
                                <div className="expertise-box">
                                    <h3 className="h4">BY INDUSTRY</h3>
                                    <ul className="unformatted-list">
                                        <li><div>Cosmetics</div></li>
                                        <li><div>Retail</div></li>
                                        <li><div>Jewelry</div></li>
                                        <li><div>Food &amp; Beverage</div></li>
                                        <li><div>Music &amp; Digital Distribution</div></li>
                                        <li><div>Fashion</div></li>
                                        <li><div>Eyewear</div></li>
                                    </ul>
                                </div>
                                <div className="expertise-box">
                                    <h3 className="h4">BY CAPABILITY</h3>
                                    <ul className="unformatted-list">
                                        <li><div>Digital Marketing</div></li>
                                        <li><div>Web Design</div></li>
                                        <li><div>E-Shop Development</div></li>
                                        <li><div>Video Production</div></li>
                                        <li><div>Professional Photography</div></li>
                                        <li><div>Social Media Marketing</div></li>
                                    </ul>
                                </div>
                                <div className="expertise-box">
                                    <h3 className="h4">BY PLATFORM</h3>
                                    <ul className="unformatted-list">
                                        <li><div>Wordpress Development</div></li>
                                        <li><div>Shopify Development</div></li>
                                        <li><div>Mailchimp Campaigns</div></li>
                                        <li><div>Facebook Ads</div></li>
                                        <li><div>Google Ads</div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}

export default OurExpertiseBoxes